import { DownloadTemplate } from '../en-ca/downloads'

export default DownloadTemplate(
  [
    {
      node_locale: 'en-CA',
      relativePath: '/en-ca/downloads',
    },
  ],
  {
    nodeLocale: 'fr-CA',
    seo: {
      title: 'Téléchargements',
      description: {
        description:
          "CRG s'efforce de fournir un contenu utile sur nos matériaux, produits et applications",
      },
    },
  }
)
